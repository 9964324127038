.home-header {
    display: grid;
    grid-template-columns: auto max-content max-content auto;
    align-items: center;
    background: #fff;
    padding: 16px;
}

.home-header-project-name {
    font-weight: bold;
    font-size: 22px;
}

.home-header-project-logo {
    height: 100px;
}

.home-header-project-logo img {
    height: 100%;
}

.home-participant-identifier {
    background: #fff;
    text-align: center;
    font-size: 14px;
    padding-bottom: 16px;
}