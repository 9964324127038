body {
    background: #f5f5f5;
}

.manage-symptom-log .mdhui-action {
    border-bottom: solid 1px #ddd;
}

.manage-symptom-log .mdhui-action .title {
    font-size: .88em;
}
