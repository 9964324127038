.toilet-seat-image {
    padding: 16px;
    text-align: center;
}

.toilet-seat-image-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.toilet-seat-image img {
    max-width: 80%;
    max-height: 600px;
}

.toilet-seat-image-not-found {
    margin-top: 40px;
    font-size: 14px;
    color: var(--color-muted2);
}