.project-support > .title {
    font-weight: bold;
    padding: 16px;
    padding-bottom: 0;
}

.project-support .mdhui-action {
    color: var(--color-primary);
    border-bottom: solid 1px var(--color-border);
}

.project-support .mdhui-action .subtitle {
    color: var(--color-primary);
}